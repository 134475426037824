import { useState } from "react";
import { useMonth } from "./MonthContext";
import {
  DateCalendar,
  DayCalendarSkeleton,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { Frequency } from "../../context/interfaces";
import CheckIcon from "@mui/icons-material/Check";
import { Badge } from "@mui/material";

const MontDateCalendar = () => {
  const {
    highlightedDays,
    currentMonth,
    currentYear,
    highlightedHabit,
    selectedDay,
    setCurrentMonth,
    setCurrentYear,
    setHighlightedDays,
    setHighlightedHabit,
    setSelectedDay,
  } = useMonth();
  const [isLoading, setIsLoading] = useState(false);

  const handleDayChange = (date: Date | null) => {
    if (date) setSelectedDay(date);
  };
  const handleMonthChange = (date: Date) => {
    setIsLoading(true);
    setCurrentMonth(date.getMonth());
    setCurrentYear(date.getFullYear());
    setIsLoading(false);
  };
  function ServerDay(
    props: PickersDayProps<Date> & {
      highlightedDays?: number[];
      frequency?: Frequency;
    }
  ) {
    const {
      highlightedDays = [],
      day,
      outsideCurrentMonth,
      frequency,
      ...other
    } = props;

    let badgeContent: JSX.Element | undefined = undefined;
    const isSelected =
      !outsideCurrentMonth && highlightedDays.indexOf(day.getDate()) >= 0;

    if (isSelected) {
      switch (frequency) {
        case Frequency.Daily:
          badgeContent = <CheckIcon fontSize="small" color="primary" />;
          break;
        case Frequency.Weekly:
          badgeContent = <CheckIcon fontSize="small" color="success" />;

          break;
        case Frequency.Monthly:
          badgeContent = <CheckIcon fontSize="small" color="error" />;
          break;
        default:
          badgeContent = undefined;
      }
    }

    return (
      <Badge overlap="circular" badgeContent={badgeContent}>
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }
  const currentMonthDate = new Date(currentYear, currentMonth);

  return (
    <DateCalendar
      value={currentMonthDate}
      onChange={(selectedDay) => handleDayChange(selectedDay as Date)}
      loading={isLoading}
      onMonthChange={handleMonthChange}
      renderLoading={() => <DayCalendarSkeleton />}
      slots={{ day: ServerDay }}
      slotProps={{
        day: {
          highlightedDays,
          frequency: highlightedHabit?.frequency,
        } as any,
      }}
    />
  );
};

export default MontDateCalendar;
