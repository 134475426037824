import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Button, Container } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const NotFound = () => {
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <SentimentVeryDissatisfiedIcon style={{ fontSize: 80, color: '#f50057' }} />
        <Typography variant="h3" component="h1" gutterBottom>
          Sorry!
        </Typography>
        <Typography variant="h6" component="p" gutterBottom>
          That page doesn't exist
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/"
        >
          Back to the homepage...
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
