import { TableHead, TableRow, TableCell } from "@mui/material";
import { useThisWeek } from "./ThisWeekContext";
import { daysOfWeek, getYYYYmmdd } from "../../utils/dateUtils";
import { Link } from "react-router-dom";
import theme from "../../context/theme";
import { useMemo } from "react";
const today = new Date(); // Reference today's date once for efficiency
today.setHours(0, 0, 0, 0); // Normalize the time part if necessary

const isToday = (date: Date) =>
  date.getDate() === today.getDate() &&
  date.getMonth() === today.getMonth() &&
  date.getFullYear() === today.getFullYear();

const todayStyle = {
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
};

const commonStyle = {
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ThisWeekTableHeader = () => {
  const { dates } = useThisWeek();

  const datesThisWeek = useMemo(() => dates?.datesThisWeek || [], [dates]);

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ border: "none" }}></TableCell>
        {datesThisWeek.map((date) => (
          <TableCell
            key={date.toString()} // Use a unique key
            size="small"
            padding="none"
            align="center"
            sx={{ border: "none" }}
          >
            {daysOfWeek[date.getDay()]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell sx={{ border: "none" }}></TableCell>
        {datesThisWeek.map((date) => (
          <TableCell
            key={date.toString()}
            size="small"
            padding="none"
            align="center"
          >
            <Link
              to={`/day/${getYYYYmmdd(date)}`}
              style={{ textDecoration: "none" }}
            >
              <div
                style={{
                  ...commonStyle,
                  ...(isToday(date) ? todayStyle : { color: "black" }),
                  margin: "auto",
                }}
              >
                {date.getDate()}
              </div>
            </Link>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ThisWeekTableHeader;
