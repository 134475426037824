import { AppBar, Box, Container, Typography } from "@mui/material";
import theme from "../../context/theme";

const AllHabitsTitleBar = () => {
  return (
    <AppBar position="static" color="default" sx={{ marginTop: "10px" }}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "10px" }}
        >
          <Typography
            variant="h6"
            sx={{ color: theme.palette.info.main }}
            component="div"
          >
            All Habits
          </Typography>
        </Box>
      </Container>
    </AppBar>
  );
};

export default AllHabitsTitleBar;
