import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { parseDateString } from "../../utils/dateUtils";
import DaysHabitList from "./DaysHabitList";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Grid, Paper } from "@mui/material";
import DayPageNavigation from "./DayPageNavigation";

const DayPage = () => {
  const { id } = useParams();
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  if (id) {
    const parsedDate = parseDateString(id);
    if (parsedDate) {
      currentDate = new Date(parsedDate.year, parsedDate.month, parsedDate.day);
    }
  }

  const [date, setDate] = useState<Date>(currentDate);

  useEffect(() => {
    setDate(currentDate);
  }, []);

  return (
    <>
      <DayPageNavigation
        date={date}
        setDate={setDate}
        currentDate={currentDate}
      />
      <Grid container direction={{ xs: "column", lg: "row" }} spacing={2}>
        <Grid item xs={12} lg={5}>
          <Paper elevation={4} sx={{ marginTop: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateCalendar
                value={date}
                onChange={(newValue: Date | null) => {
                  if (newValue) {
                    setDate(newValue);
                  }
                }}
              />
            </LocalizationProvider>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={7}>
          <DaysHabitList date={date} />
        </Grid>
      </Grid>
    </>
  );
};

export default DayPage;
