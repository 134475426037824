export const dayArray = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];

export const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const monthsOfYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const fullMonthsOfYear = [
  "January","February","March", "April","May","June","July","August","September","October","November","December"]


type ParsedDate = {
  year: number;
  month: number;
  day: number;
};

export function formatDate(date: Date) {
  const day = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dateNum = date.getDate();
  const year = date.getFullYear();

  return `${day}, ${month} ${dateNum}, ${year}`;
}

export const parseDateString = (dateString: string|null): ParsedDate | null => {
  const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
  const match = dateString?.match(regex);

  if (match) {
    const year = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1;
    const day = parseInt(match[3], 10);

    return { year, month, day };
  }

  return null;
};

export function getYYYYmmdd(date: Date = new Date()) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-based in JavaScript
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // months are 0-based in JavaScript
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

interface getDayOfWeekProps {
  date?: Date;
}

export function getDayOfWeek(props: getDayOfWeekProps) {
  const currentDate = props.date ? new Date(props.date) : new Date();
  return { day: daysOfWeek[currentDate.getDay()], index: currentDate.getDay() };
}

export const getDaysBetweenDates = (startDate: Date, endDate: Date): number => {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  // Use getTime() to get the time in milliseconds and then find the difference
  const diffInMilliseconds = Math.abs(endDate.getTime() - startDate.getTime());

  // Calculate the days
  const diffInDays = Math.round(diffInMilliseconds / oneDayInMilliseconds);

  return diffInDays;
};

export const changeDateByIncrement = (date: Date, increment: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + increment);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const getDates = (currentDate: Date) => {
  currentDate.setHours(0, 0, 0, 0);
  const pastSunday = changeDateByIncrement(
    currentDate,
    -currentDate.getDay()
  );
  const comingSaturday = changeDateByIncrement(pastSunday, 6);
  const datesThisWeek = Array.from({ length: 7 }, (_, i) =>
    changeDateByIncrement(pastSunday, i)
  );

  return {
    pastSunday,
    comingSaturday,
    datesThisWeek,
    currentDate,
  };
};