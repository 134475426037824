import Habit, { Frequency } from "../context/interfaces";
import { parseDateString, getYYYYmmdd } from "./dateUtils";
import { countDaysDoneThisWeek, countDaysDoneThisMonth } from "./habitUtils";

export const streak = (
  habit: Habit | null,
  day: Date
): {
  currentStreak: number;
  longestStreak: number;
  frequency: Frequency;
  completions: number;
  percentageComplete: number;
} => {
  if (!habit) {
    return {
      currentStreak: 0,
      longestStreak: 0,
      frequency: Frequency.Daily,
      completions: 0,
      percentageComplete: 0,
    };
  }
  const {
    frequency,
    startDate: startDateString,
    daysOfWeek,
    numberOfPeriods,
    daysDone,
    dateArchived: archivedDateString,
  } = habit;
  const startDateParsed = parseDateString(startDateString);
  let startDate: Date = new Date(day.getTime());
  if (startDateParsed) {
    startDate = new Date(
      startDateParsed?.year,
      startDateParsed?.month,
      startDateParsed?.day
    );
  }
  const dateArchivedParsed =
    archivedDateString && parseDateString(archivedDateString);
  let dateArchived: Date = new Date(day.getTime());
  if (dateArchivedParsed) {
    dateArchived = new Date(
      dateArchivedParsed.year,
      dateArchivedParsed.month,
      dateArchivedParsed.day
    );
  }
  startDate.setHours(0, 0, 0, 0);
  let currentStreak = 0;
  let longestStreak = 0;
  let streak = 0;
  let isCurrentStreak = true;
  let completions = 0;
  let nonCompletions = 0;
  if (!day) {
    day = new Date();
  }
  day.setHours(0, 0, 0, 0);
  let daysDoneReverseSorted = [...daysDone].sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateB.getTime() - dateA.getTime();
  });

  // Function to update streaks
  const updateStreaks = () => {
    if (isCurrentStreak) currentStreak++;
    streak++;
    completions++;
    longestStreak = Math.max(longestStreak, streak);
  };

  // Function to reset streaks
  const resetStreaks = () => {
    isCurrentStreak = false;
    streak = 0;
    nonCompletions++;
  };

  if (day.getTime() < startDate.getDate()) {
    return {
      currentStreak: 0,
      longestStreak: 0,
      frequency,
      completions: 0,
      percentageComplete: 0,
    };
  }
  if (frequency === Frequency.Daily) {
    let date = new Date(day);
    if (dateArchived && dateArchived < date) {
      date = dateArchived;
    }
    for (
      date;
      date.getTime() >= startDate.getTime();
      date.setDate(date.getDate() - 1)
    ) {
      const dateString = getYYYYmmdd(date);
      if (
        daysDoneReverseSorted.includes(dateString)
        //i.e. not scheduled for that day of the week
      ) {
        updateStreaks();
      } else if (
        getYYYYmmdd(date) !== getYYYYmmdd(day) &&
        daysOfWeek[date.getDay()]
      ) {
        resetStreaks();
      }
    }
  } else if (frequency === Frequency.Weekly) {
    let date = new Date(day);
    if (dateArchived && dateArchived < date) {
      date = dateArchived;
    }
    let finalSaturday = new Date(date);
    finalSaturday.setDate(finalSaturday.getDate() - finalSaturday.getDay() + 6);
    for (
      date.setDate(finalSaturday.getDate());
      date.getTime() >= startDate.getTime();
      date.setDate(date.getDate() - 7)
    ) {
      if (countDaysDoneThisWeek({ habit, date }) >= numberOfPeriods) {
        updateStreaks();
      } else {
        if (getYYYYmmdd(date) !== getYYYYmmdd(finalSaturday)) {
          resetStreaks();
        }
      }
    }
  } else if (frequency === Frequency.Monthly) {
    let date = new Date(day.getTime());
    while (
      (date.getFullYear() > startDate.getFullYear() ||
        (date.getFullYear() === startDate.getFullYear() &&
          date.getMonth() >= startDate.getMonth())) &&
      (date.getFullYear() < dateArchived.getFullYear() ||
        (date.getFullYear() === dateArchived.getFullYear() &&
          date.getMonth() <= dateArchived.getMonth()))
    ) {
      if (countDaysDoneThisMonth({ habit, date }) >= numberOfPeriods) {
        updateStreaks();
      } else if (getYYYYmmdd(date) !== getYYYYmmdd(day)) {
        resetStreaks();
      }

      // Handle end of loop iteration
      let newYear = date.getFullYear();
      let newMonth = date.getMonth() - 1;
      if (newMonth < 0) {
        newMonth = 11; // December
        newYear -= 1; // Previous year
      }

      // Calculate last day of the new month
      const lastDay = new Date(newYear, newMonth + 1, 0).getDate();
      const newDay = Math.max(day.getDate(), lastDay);

      // Create a new date object for the next iteration
      date = new Date(newYear, newMonth, newDay);
      date.setHours(0, 0, 0, 0);
    }
  }

  const percentageComplete =
    completions + nonCompletions > 0
      ? (completions / (completions + nonCompletions)) * 100
      : 0;
  let date = new Date(day);
  if (dateArchived && dateArchived < date) {
    currentStreak = 0;
  }

  return {
    currentStreak,
    longestStreak,
    frequency,
    completions,
    percentageComplete,
  };
};
