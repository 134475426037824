import { AppBar, Box, Button, Container, Typography } from "@mui/material";
import { changeDateByIncrement, formatDate } from "../../utils/dateUtils";
import theme from "../../context/theme";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type DayPageNavigationProps = {
  date: Date;
  setDate: (newDate: Date) => void;
  currentDate: Date;
};

const DayPageNavigation = ({
  date,
  setDate,
  currentDate,
}: DayPageNavigationProps) => {
  const handleArrowClick = (increment: number) => {
    const newDate = changeDateByIncrement(date, increment);
    setDate(newDate);
  };

  return (
    <AppBar position="static" color="default" sx={{ marginTop: "10px" }}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "10px" }}
        >
          <Typography
            variant="h6"
            sx={{ color: theme.palette.info.main }}
            component="div"
          >
            {formatDate(date)}
          </Typography>
          <Box>
            <Button
              variant="outlined"
              color="info"
              onClick={() => handleArrowClick(-1)}
            >
              <ArrowBackIosNewIcon color="info" />
            </Button>
            <Button
              variant="outlined"
              color="info"
              onClick={() => setDate(new Date())}
            >
              Today
            </Button>
            <Button
              variant="outlined"
              color="info"
              onClick={() => handleArrowClick(1)}
            >
              <ArrowForwardIosIcon color="info" />
            </Button>
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};

export default DayPageNavigation;
