import { Paper, Grid } from "@mui/material";
import { useEffect } from "react";
import { useThisWeek } from "./ThisWeekContext";
import useFetch from "../../hooks/useFetch";
import { getDates } from "../../utils/dateUtils";
import ThisWeekNavigation from "./ThisWeekNavigation";
import ThisWeekTable from "./ThisWeekTable";
import ThisWeekDateCalendar from "./ThisWeekDateCalendar";
import { useHabits } from "../../context/HabitsContext";

const ThisWeekPageContents = () => {
  const { dates, setDates } = useThisWeek();
  let oldDate = new Date();
  if (dates) {
    oldDate = dates.currentDate;
  }
  const { comingSaturday, pastSunday, datesThisWeek, currentDate } =
    getDates(oldDate);

  const { habits, setHabits } = useHabits();
  const { data, isPending, error } = useFetch(
    `${process.env.REACT_APP_API_URL}/habits`
  );

  useEffect(() => {
    if (data) {
      setHabits(data);
    }
  }, [data, setHabits]);

  useEffect(() => {
    setDates({
      pastSunday,
      comingSaturday,
      currentDate,
      datesThisWeek,
    });
  }, []);
  return (
    <>
      <ThisWeekNavigation />
      <Grid container direction={{ xs: "column", lg: "row" }} spacing={2}>
        <Grid item xs={12} lg={5}>
          <Paper elevation={4} sx={{ marginTop: "20px" }}>
            <ThisWeekDateCalendar />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Paper
            elevation={4}
            sx={{
              padding: "10px 20px 20px 20px",
              // marginLeft: "20px",
              marginTop: { lg: "20px", xs: "0px" },
            }}
          >
            <ThisWeekTable habits={habits} setHabits={setHabits} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ThisWeekPageContents;
