import { useEffect, useState } from "react";
import { getYYYYmmdd, parseDateString } from "../../utils/dateUtils";
import Habit, { frequencyToNumber } from "../../context/interfaces";
import useFetch from "../../hooks/useFetch";
import { Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMonth } from "./MonthContext";
import MonthPageNavigation from "./MonthPageNavigation";
import MontDateCalendar from "./MonthDateCalendar";
import MonthPageHabitList from "./MonthPageHabitList";
import { useHabits } from "../../context/HabitsContext";

const MonthPageContents = () => {
  // Replace useState calls with useMonth
  const {
    highlightedDays,
    currentMonth,
    currentYear,
    highlightedHabit,
    selectedDay,
    setCurrentMonth,
    setCurrentYear,
    setHighlightedDays,
    setHighlightedHabit,
    setSelectedDay,
  } = useMonth();

  const [allHabitsErrorMessage, setAllHabitsErrorMessage] = useState<
    string | null
  >(null);

  const { habits, setHabits } = useHabits();
  const { data: allHabitsData, error: allHabitsError } = useFetch(
    `${process.env.REACT_APP_API_URL}/habits`
  );

  useEffect(() => {
    if (allHabitsError) {
      setAllHabitsErrorMessage("Failed to load All Habits. Please try again.");
    } else {
      setAllHabitsErrorMessage(null);
    }
    if (allHabitsData) {
      setHabits(allHabitsData);
      let allHabits: Habit[] = allHabitsData;
      const allHabitsSorted = allHabits
        .sort(
          (a, b) =>
            frequencyToNumber(a.frequency) - frequencyToNumber(b.frequency)
        )
        .map((habit) => habit);
      setHighlightedHabit(allHabitsSorted[0]);
    }
  }, [allHabitsData, allHabitsError, setHabits]);

  useEffect(() => {
    if (highlightedHabit) {
      type ParsedDate = {
        year: number;
        month: number;
        day: number;
      };
      const daysDone: (ParsedDate | null)[] = highlightedHabit.daysDone.map(
        (day: string) => parseDateString(day)
      );

      const nonNullDaysDone: ParsedDate[] = daysDone.filter(
        (date): date is ParsedDate => date !== null
      );

      const daysDoneThisMonth = nonNullDaysDone
        .filter((date) => date.month === currentMonth)
        .filter((date) => date.year === currentYear)
        .map((date) => date.day);
      setHighlightedDays(daysDoneThisMonth);
    }
  }, [highlightedHabit, currentMonth]);
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedDay) navigate(`/day/${getYYYYmmdd(selectedDay)}`);
  }, [selectedDay]);

  return (
    <>
      <MonthPageNavigation />
      <Grid container direction={{ xs: "column", lg: "row" }} spacing={2}>
        <Grid item xs={12} lg={5}>
          {allHabitsErrorMessage && (
            <Typography color="error">{allHabitsErrorMessage}</Typography>
          )}
          <Paper elevation={4} sx={{ marginTop: "20px" }}>
            <MontDateCalendar />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Paper
            elevation={4}
            sx={{
              marginTop: { lg: "20px", xs: "0px" },
              // marginLeft: "20px",
              padding: "10px 20px 10px 20px",
            }}
          >
            <MonthPageHabitList habits={habits} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default MonthPageContents;
