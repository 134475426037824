import Habit from "../../context/interfaces";
import HabitList from "../../components/HabitList";
import { useMonth } from "./MonthContext";
import { parseDateString } from "../../utils/dateUtils";

export interface MonthPageHabitListProps {
  habits: Habit[];
}

const MonthPageHabitList = ({ habits }: MonthPageHabitListProps) => {
  const {
    highlightedDays,
    currentMonth,
    currentYear,
    highlightedHabit,
    selectedDay,
    setCurrentMonth,
    setCurrentYear,
    setHighlightedDays,
    setHighlightedHabit,
    setSelectedDay,
  } = useMonth();
  const filteredHabits = habits.filter((habit) => {
    const parsedDateArchived = parseDateString(habit.dateArchived);
    const dateArchived = parsedDateArchived
      ? new Date(
          parsedDateArchived.year,
          parsedDateArchived.month,
          parsedDateArchived.day
        )
      : null;
    dateArchived?.setHours(0, 0, 0, 0);
    const parsedStartDate = parseDateString(habit.startDate);
    const startDate = parsedStartDate
      ? new Date(
          parsedStartDate.year,
          parsedStartDate.month,
          parsedStartDate.day
        )
      : new Date();
    startDate?.setHours(0, 0, 0, 0);
    return (
      (!dateArchived ||
        (dateArchived && currentYear < dateArchived.getFullYear()) ||
        (currentYear === dateArchived.getFullYear() &&
          currentMonth <= dateArchived.getMonth())) &&
      (currentYear > startDate.getFullYear() ||
        (currentYear === startDate.getFullYear() &&
          currentMonth >= startDate.getMonth()))
    );
  });
  return (
    <>
      {habits && (
        <HabitList
          highlightedIds={[highlightedHabit?._id as string]}
          habits={filteredHabits}
          title=""
          onClick={(_id) => {
            // Find the habit with the given ID and set it as the highlighted habit
            const selectedHabit = habits.find((habit) => habit._id === _id);
            if (selectedHabit) {
              setHighlightedHabit(selectedHabit);
            }
          }}
        />
      )}
    </>
  );
};

export default MonthPageHabitList;
