import React, { createContext, useState, useContext, ReactNode } from "react";

export type DatesObject = {
  pastSunday: Date;
  comingSaturday: Date;
  currentDate: Date;
  datesThisWeek: Date[];
};

// Adjust the type definition for habitMatrix here
// type HabitMatrixType = Habit[][]; // Change this to your actual type

interface ThisWeekContextProps {
  dates: DatesObject | undefined;
  setDates: React.Dispatch<React.SetStateAction<DatesObject | undefined>>;
  // habitMatrix: HabitMatrixType | undefined;
  // setHabitMatrix: React.Dispatch<
  // React.SetStateAction<HabitMatrixType | undefined>
  // >;
}

const defaultDates: DatesObject = {
  pastSunday: new Date(),
  comingSaturday: new Date(),
  currentDate: new Date(),
  datesThisWeek: [
    new Date(),
    new Date(),
    new Date(),
    new Date(),
    new Date(),
    new Date(),
    new Date(),
  ],
};

export const ThisWeekContext = createContext<ThisWeekContextProps>({
  dates: defaultDates,
  setDates: () => {},
  // habitMatrix: undefined,
  // setHabitMatrix: () => {},
});

interface ThisWeekProviderProps {
  children: ReactNode;
}

export const ThisWeekProvider: React.FC<ThisWeekProviderProps> = ({
  children,
}) => {
  const [dates, setDates] = useState<DatesObject | undefined>();
  // const [habitMatrix, setHabitMatrix] = useState<HabitMatrixType | undefined>();

  return (
    <ThisWeekContext.Provider
      value={{ dates, setDates /*, habitMatrix, setHabitMatrix*/ }}
    >
      {children}
    </ThisWeekContext.Provider>
  );
};

export const useThisWeek = () => {
  const context = useContext(ThisWeekContext);
  if (!context) {
    throw new Error("useThisWeek must be used within a ThisWeekProvider");
  }
  return context;
};
