import { Paper, Select, MenuItem, FormControl } from "@mui/material";
import { Frequency } from "../context/interfaces";
import {
  habitDetailsSx,
  selectStyle,
} from "../pages/HabitDetails/HabitDetailsStyles";

interface HabitFormFrequencySelectProps {
  frequency: Frequency;
  setFrequency: (frequency: Frequency) => void;
  setIsDaily: (isDaily: boolean) => void;
  setIsWeekly: (isWeekly: boolean) => void;
}

const HabitFormFrequencySelect = ({
  frequency,
  setFrequency,
  setIsDaily,
  setIsWeekly,
}: HabitFormFrequencySelectProps) => {
  return (
    <Paper sx={habitDetailsSx(null)}>
      <FormControl margin="none" variant="standard" fullWidth>
        <Select
          labelId="frequency-label"
          value={frequency}
          onChange={(e) => {
            const newFrequency = e.target.value as Frequency;
            setFrequency(newFrequency);
            if (newFrequency === Frequency.Daily) {
              setIsDaily(true);
              setIsWeekly(false);
            } else {
              setIsDaily(false);
              if (newFrequency === Frequency.Weekly) {
                setIsWeekly(true);
              } else {
                setIsWeekly(false);
              }
            }
          }}
          sx={selectStyle}
        >
          <MenuItem value={Frequency.Daily}>Daily</MenuItem>
          <MenuItem value={Frequency.Weekly}>Weekly</MenuItem>
          <MenuItem value={Frequency.Monthly}>Monthly</MenuItem>
        </Select>
      </FormControl>
    </Paper>
  );
};

export default HabitFormFrequencySelect;
