import Habit, { Frequency, frequencyToNumber } from "../context/interfaces";
import { dayArray, parseDateString } from "../utils/dateUtils";
import { Button, Typography, Card, CardContent, Stack } from "@mui/material";
import Streak, { StreakDisplayType } from "./Streak";
import theme from "../context/theme";
import { useMonth } from "../pages/MonthPage/MonthContext";
import { useHabits } from "../context/HabitsContext";
import { useEffect } from "react";
import useFetch from "../hooks/useFetch";

interface HabitListProps {
  habits: Habit[];
  title: string;
  onClick?: (_id: string) => void;
  highlightedIds?: string[];
}

const HabitList = (props: HabitListProps) => {
  const { habits: filteredHabits, title, onClick, highlightedIds } = props;

  const allDaysOfWeek = filteredHabits.map((habit) => {
    return habit.daysOfWeek
      .map((day, index) => (day ? dayArray[index] : null))
      .filter(Boolean)
      .join(" ");
  });

  const habitStyles = (habit: Habit) => {
    let style: any = { marginBottom: "5px" };
    if (highlightedIds?.includes(habit._id as string)) {
      style =
        habit.frequency === Frequency.Daily
          ? { ...style, backgroundColor: theme.palette.primary.dark }
          : habit.frequency === Frequency.Weekly
          ? { ...style, backgroundColor: theme.palette.success.dark }
          : { ...style, backgroundColor: theme.palette.error.dark };
    } else {
      style =
        habit.frequency === Frequency.Daily
          ? { ...style, backgroundColor: theme.palette.primary.light }
          : habit.frequency === Frequency.Weekly
          ? { ...style, backgroundColor: theme.palette.success.light }
          : { ...style, backgroundColor: theme.palette.error.light };
    }
    return style;
  };

  return (
    <>
      {filteredHabits
        .sort(
          (a, b) =>
            frequencyToNumber(a.frequency) - frequencyToNumber(b.frequency)
        )
        .map((habit, index) => (
          <Card key={habit._id} sx={habitStyles(habit)}>
            <CardContent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 5,
                paddingLeft: 10,
                paddingBottom: 5,
                paddingRight: 5,
              }}
            >
              <Button
                sx={{
                  color: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
                fullWidth
                onClick={() => onClick && habit._id && onClick(habit._id)}
              >
                <Stack spacing={0}>
                  <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                    {habit.name}
                  </Typography>
                  <Streak
                    habit={habit}
                    date={new Date()}
                    streakDisplayType={StreakDisplayType.Sentence}
                  />
                </Stack>
                <Typography variant="subtitle1" sx={{ textAlign: "right" }}>
                  {habit.frequency === Frequency.Daily && (
                    <>{allDaysOfWeek[index]}</>
                  )}
                  {habit.frequency === Frequency.Weekly && (
                    <>{habit.numberOfPeriods} / wk</>
                  )}
                  {habit.frequency === Frequency.Monthly && (
                    <>{habit.numberOfPeriods} / mth</>
                  )}
                </Typography>
              </Button>
            </CardContent>
          </Card>
        ))}
    </>
  );
};

export default HabitList;
