import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';

interface ConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  action?: string;
  question?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  onClose,
  onConfirm,
  action = 'Confirm',
  question = 'Are you sure?',
}) => {
  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle>{action}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {question}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          color="error"
        >
          {action}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
