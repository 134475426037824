import { Paper, Typography } from "@mui/material";
import Streak, { StreakDisplayType, StreakType } from "../../components/Streak";
import { StreakBoxProps } from "./CurrentStreakBox";
import { habitDetailsSx } from "./HabitDetailsStyles";

const HabitCompletionRateBox = ({ habit, date }: StreakBoxProps) => {
  return (
    <Paper sx={habitDetailsSx(habit)} key={`completionRate-${habit?._id}`}>
      <Typography variant="subtitle1" sx={{ color: "white" }}>
        Completion rate:
      </Typography>
      <Streak
        habit={habit}
        date={date}
        variant={"subtitle1"}
        color="white"
        streakDisplayType={StreakDisplayType.Percentage}
        streakType={StreakType.PercentageComplete}
      />
    </Paper>
  );
};

export default HabitCompletionRateBox;
