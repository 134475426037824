import { useState } from "react";
import axios from "axios";

const useDelete = (url: string) => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const deleteData = async () => {
    setIsPending(true);

    try {
      await axios.delete(url);
      setError(null);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsPending(false);
    }
  };

  return { isPending, error, deleteData };
};

export default useDelete;
