import Habit, { Frequency, HabitState } from "../context/interfaces";
import { DatesObject } from "../pages/ThisWeek/ThisWeekContext";
import { getYYYYmmdd, parseDateString } from "./dateUtils";

export const isHabitDueThisWeek = (habit: Habit, dates: DatesObject) => {
  return habit.startDate <= getYYYYmmdd(dates.comingSaturday);
};

export const allWeeklyHabits = (habits: Habit[], date: Date) => {
  const listWeeklyHabits = habits.filter(
    (habit) =>
      getYYYYmmdd(date) >= habit.startDate &&
      habit.frequency === Frequency.Weekly
  );
  return listWeeklyHabits;
};

export const allMonthlyHabits = (habits: Habit[], date: Date) => {
  const listMonthlyHabits = habits.filter(
    (habit) =>
      getYYYYmmdd(date) >= habit.startDate &&
      habit.frequency === Frequency.Monthly
  );
  return listMonthlyHabits;
};

export const allDailyHabits = (habits: Habit[], date: Date) => {
  const dayOfWeek = date.getDay();
  const listDailyHabits = habits.filter(
    (habit) =>
      habit.daysOfWeek[dayOfWeek] &&
      habit.frequency === Frequency.Daily &&
      getYYYYmmdd(date) >= habit.startDate
  );
  return listDailyHabits;
};

export const isHabitDue = (habit: Habit, date: Date): boolean => {
  const parsedDate = parseDateString(habit.startDate);
  const startDate = parsedDate
    ? new Date(parsedDate?.year, parsedDate?.month, parsedDate.day)
    : new Date(habit.startDate);

  const parsedDateArchived =
    habit.dateArchived && parseDateString(habit.dateArchived);
  const dateArchived =
    parsedDateArchived &&
    new Date(
      parsedDateArchived.year,
      parsedDateArchived.month,
      parsedDateArchived.day
    );

  // Calculate the day of the week (0-6, where 0 is Sunday)
  const dayOfWeek = date.getDay();

  // Calculate the days between today and the start date
  if (date < startDate || (dateArchived && date > dateArchived)) {
    return false;
  }

  return (
    (habit.frequency === Frequency.Daily && habit.daysOfWeek[dayOfWeek]) ||
    (habit.frequency === Frequency.Weekly &&
      countDaysDoneThisWeek({ habit, date }) < habit.numberOfPeriods) ||
    (habit.frequency === Frequency.Monthly &&
      countDaysDoneThisMonth({ habit, date }) < habit.numberOfPeriods)
  );
};

export const habitStatus = (habit: Habit, date: Date): HabitState => {
  const parsedDate = parseDateString(habit.startDate);
  const startDate = parsedDate
    ? new Date(parsedDate?.year, parsedDate?.month, parsedDate.day)
    : new Date(habit.startDate);
  if (date < startDate) {
    return HabitState.NotDue;
  }
  if (habit.daysDone.includes(getYYYYmmdd(date))) {
    return HabitState.Done;
  }
  if (isHabitDue(habit, date)) {
    return HabitState.Due;
  }
  return HabitState.NotDue;
};

interface habitDateProps {
  habit: Habit;
  date?: Date;
}

export const countDaysDoneThisWeek = ({
  habit,
  date = new Date(),
}: habitDateProps) => {
  const lastSunday = new Date(date);
  const nextSaturday = new Date(date);

  // Reset time to start of the day
  lastSunday.setHours(0, 0, 0, 0);

  // Set date to last Sunday
  lastSunday.setDate(date.getDate() - date.getDay());
  nextSaturday.setDate(date.getDate() - date.getDay() + 6);

  const count = habit.daysDone.filter((day: string) => {
    const parsedDate = parseDateString(day);
    const dayDate = parsedDate
      ? new Date(parsedDate.year, parsedDate?.month, parsedDate?.day)
      : new Date(day);

    // Reset time to start of the day for comparison
    dayDate.setHours(0, 0, 0, 0);
    return dayDate >= lastSunday && dayDate <= nextSaturday;
  }).length;
  return count;
};

export const countDaysDoneThisMonth = ({
  habit,
  date = new Date(),
}: habitDateProps) => {
  const firstOfMonth = new Date(date);

  // Reset time to start of the day
  firstOfMonth.setHours(0, 0, 0, 0);

  // Set date to first of month
  firstOfMonth.setDate(1);

  const count = habit.daysDone.filter((day: string) => {
    const parsedDate = parseDateString(day);
    const dayDate = parsedDate
      ? new Date(parsedDate.year, parsedDate?.month, parsedDate?.day)
      : new Date(day);
    // Reset time to start of the day for comparison
    dayDate.setHours(0, 0, 0, 0);
    return dayDate >= firstOfMonth && dayDate.getMonth() === date.getMonth();
  }).length;

  return count;
};

export const sortedHabitsbyFrequency = (habits: Habit[]) =>
  habits.sort((a, b) => {
    const order = {
      [Frequency.Daily]: 1,
      [Frequency.Weekly]: 2,
      [Frequency.Monthly]: 3,
    };

    return order[a.frequency] - order[b.frequency];
  });

export const endOfMonth = (date: Date) => {
  const parsedDate = parseDateString(getYYYYmmdd(date));
  let endOfMonth = new Date(date);
  if (parsedDate) {
    endOfMonth = new Date(parsedDate?.year, parsedDate?.month, parsedDate?.day);
  }
  endOfMonth.setHours(0, 0, 0, 0);
  endOfMonth.setDate(1);
  endOfMonth.setMonth(endOfMonth.getMonth() + 1);
  endOfMonth.setDate(endOfMonth.getDate() - 1);
  return endOfMonth;
};
