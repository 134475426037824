import React, { createContext, useState, useContext, ReactNode } from "react";
import Habit from "./interfaces";

interface HabitsContextProps {
  habit: Habit | null;
  setHabit: (habit: Habit | null) => void;
  habits: Habit[];
  setHabits: React.Dispatch<React.SetStateAction<Habit[]>>;
}

export const HabitsContext = createContext<HabitsContextProps | undefined>(
  undefined
);

interface HabitsProviderProps {
  children: ReactNode;
}

export const HabitsProvider: React.FC<HabitsProviderProps> = ({ children }) => {
  const [habit, setHabit] = useState<Habit | null>(null);
  const [habits, setHabits] = useState<Habit[]>([]);

  const value = {
    habit,
    setHabit,
    habits,
    setHabits,
  };

  return (
    <HabitsContext.Provider value={value}>{children}</HabitsContext.Provider>
  );
};

export const useHabits = () => {
  const context = useContext(HabitsContext);
  if (!context) {
    throw new Error("useHabit must be used within a HabitDetailsProvider");
  }
  return context;
};
