import { useState, useEffect } from "react";
import axios from "axios";

const useFetch = (url:string) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const source = axios.CancelToken.source();
    setIsPending(true);
    axios
      .get(url, { cancelToken: source.token })
      .then((response) => {
        setData(response.data);
        setError(null);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Axios request cancelled");
        } else {
          setError(err.message);
        }
      })
      .finally(() => {
        setIsPending(false);
      });

    return () => source.cancel();
  }, [url]);

  const refetch = () => {
    setIsPending(true);
    axios
      .get(url)
      .then((response) => {
        setData(response.data);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return { data, isPending, error, refetch };
};

export default useFetch;
