import { useNavigate } from "react-router-dom";
import HabitForm from "../../components/HabitForm";
import usePost from "../../hooks/usePost";

const CreateNewHabitBox = () => {
  const navigate = useNavigate();
  const { data, isPending, error, postData } = usePost(
    `${process.env.REACT_APP_API_URL}/habits`
  );

  const handleCancel = () => {
    navigate("/");
  };

  const handleSubmit = (habit: any) => {
    postData(habit).then(() => {
      navigate({ pathname: "/" });
    });
  };
  return (
    // <></>
    <HabitForm
      formType="Add"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      isPending={isPending}
    />
  );
};

export default CreateNewHabitBox;
