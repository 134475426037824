export interface Habit {
  _id?: string;
  name: string;
  frequency: Frequency;
  daysOfWeek: boolean[];
  numberOfPeriods: number;
  startDate: string;
  daysDone: string[];
  dateArchived: string | null;
}

export default Habit;

export enum Frequency {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}

export const frequencyToNumber = (frequency: Frequency) => {
  switch (frequency) {
    case Frequency.Daily:
      return 1;
    case Frequency.Weekly:
      return 2;
    case Frequency.Monthly:
      return 3;
    default:
      return 4; // Default case if needed
  }
};

export enum HabitState {
  Done = "Done",
  Due = "Due",
  NotDue = "NotDue",
}
