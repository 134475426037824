import { AppBar, Box, Button, Container, Typography } from "@mui/material";
import Habit from "../../context/interfaces";
import theme from "../../context/theme";

export interface HabitDetailsPageNavigationProps {
  habit: Habit;
}

const HabitDetailsPageNavigation = ({
  habit,
}: HabitDetailsPageNavigationProps) => {
  return (
    <AppBar position="static" color="default" sx={{ marginTop: "10px" }}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "10px" }}
        >
          <Typography
            variant="h6"
            sx={{ color: theme.palette.info.main }}
            component="div"
          >
            Habit: {habit && habit.name}{" "}
          </Typography>
          <Button variant="outlined" color="info" href="/all-habits">
            Go to All Habits
          </Button>
        </Box>
      </Container>
    </AppBar>
  );
};

export default HabitDetailsPageNavigation;
