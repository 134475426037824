import { useState } from "react";
import axios from "axios";

const usePut = (url:string) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const updateData = async (payload:any) => {
    setIsPending(true);

    try {
      const response = await axios.put(url, payload);
      setData(response.data);
      setError(null);
    } catch (err:any) {
      setError(err.message);
    } finally {
      setIsPending(false);
    }
  };

  return { data, isPending, error, updateData };
};

export default usePut;
