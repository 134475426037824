import { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Habit from "../../context/interfaces";
import HabitDetailsPageNavigation from "./HabitDetailsPageNavigation";
import { useHabits } from "../../context/HabitsContext";
import HabitDetailsBox from "./HabitDetailsBox";
import HabitList from "../../components/HabitList";

const HabitDetailsPage = () => {
  const { id } = useParams();

  const { habit, setHabit, habits, setHabits } = useHabits();
  const navigate = useNavigate();
  const [nonArchivedIds, setNonArchivedIds] = useState<string[]>([]);

  const {
    data: habitsData,
    isPending: habitsIsPending,
    error: habitsError,
  } = useFetch(`${process.env.REACT_APP_API_URL}/habits`);
  useEffect(() => {
    if (habitsData) {
      setHabits(habitsData);
    }
  }, [habitsData, setHabits]);
  useEffect(() => {
    const filteredHabits = habits
      .filter((habit: Habit) => !habit.dateArchived)
      .map((habit: Habit) => habit._id);
    if (filteredHabits) setNonArchivedIds(filteredHabits as string[]);
  }, [habit, habits]);
  const {
    data: habitData,
    error: habitError,
    isPending: habitIsPending,
  } = useFetch(`${process.env.REACT_APP_API_URL}/habits/${id}`);
  useEffect(() => {
    if (habitData !== null) {
      setHabit(habitData as Habit);
    }
  }, [habitData]);

  return (
    <>
      <Container>
        {(habitError || habitsError) && (
          <Typography variant="h6" color="error">
            Error! {habitError}
          </Typography>
        )}
        {(habitIsPending || habitsIsPending) && <CircularProgress />}
      </Container>
      <HabitDetailsPageNavigation habit={habit as Habit} />
      <Grid container direction={{ xs: "column", lg: "row" }} spacing={2}>
        <Grid item xs={12} lg={5}>
          <Paper
            elevation={4}
            sx={{ marginTop: "20px", padding: "15px 10px 10px 10px" }}
          >
            {habit && <HabitDetailsBox />}
          </Paper>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Paper
            elevation={4}
            sx={{
              // marginLeft: "20px",
              marginTop: { lg: "20px", xs: "0px" },
              padding: "20px 15px 20px 15px",
            }}
          >
            {habits && (
              <HabitList
                highlightedIds={nonArchivedIds}
                habits={habits}
                title="All Habits"
                onClick={(id) => {
                  navigate(`/habits/${id}`);
                }}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default HabitDetailsPage;
