import Habit, { Frequency } from "../../context/interfaces";
import theme from "../../context/theme";

export const habitDetailsSx = (habit: Habit | null) => {
  let habitDetailsSx: any = {
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 10px 10px 10px",
  };
  if (habit?.frequency === Frequency.Daily || habit === null) {
    habitDetailsSx = {
      ...habitDetailsSx,
      backgroundColor: theme.palette.primary.light,
    };
  } else {
    habitDetailsSx =
      habit?.frequency === Frequency.Weekly
        ? { ...habitDetailsSx, backgroundColor: theme.palette.success.light }
        : { ...habitDetailsSx, backgroundColor: theme.palette.error.light };
  }
  return habitDetailsSx;
};

export const habitNameSx = (habit: Habit | null) => {
  let habitNameSx: any = {
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 10px 5px 10px",
  };
  if (habit?.frequency === Frequency.Daily || habit === null) {
    habitNameSx = {
      ...habitNameSx,
      backgroundColor: theme.palette.primary.main,
    };
  } else {
    habitNameSx =
      habit?.frequency === Frequency.Weekly
        ? { ...habitNameSx, backgroundColor: theme.palette.success.main }
        : { ...habitNameSx, backgroundColor: theme.palette.error.main };
  }
  return habitNameSx;
};

export const textFieldStyle = {
  input: { color: "white", fontSize: "20px", fontWeight: 500 },
  "& .MuiInput-underline:before": {
    // Underline color when textfield is not focused
    borderBottomColor: "white",
  },
  "& .MuiInput-underline:after": {
    // Underline color when textfield is focused
    borderBottomColor: "white",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    // Underline color on hover (when not disabled)
    borderBottomColor: "white",
  },
};

export const selectStyle = {
  color: "white", // Set text color
  fontSize: "1.25rem", // h6 font size
  fontWeight: 500, // h6 font weight
  lineHeight: 1.6, // h6 line height
  "& .MuiSelect-select": {
    // Target the internal select element
    paddingTop: "0px", // Adjust the padding-top as needed
    paddingBottom: "0px", // Adjust the padding-bottom as needed
  },
  ".MuiSelect-icon": {
    // Dropdown icon
    color: "white",
  },
  "&:before": {
    // Underline default
    borderBottomColor: "white",
  },
  "&:after": {
    // Underline on focus
    borderBottomColor: "white",
  },
  "&:hover:not(.Mui-disabled):before": {
    // Underline on hover
    borderBottomColor: "white",
  },
};
