import { LocalizationProvider } from "@mui/x-date-pickers";
import MonthPageContents from "./MonthPageContents";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function MonthPage() {
  return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MonthPageContents />
      </LocalizationProvider>
  );
}

export default MonthPage;
