import { AppBar, Box, Button, Container, Typography } from "@mui/material";
import { fullMonthsOfYear } from "../../utils/dateUtils";
import theme from "../../context/theme";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMonth } from "./MonthContext";
const MonthPageNavigation = () => {
  const {
    highlightedDays,
    currentMonth,
    currentYear,
    highlightedHabit,
    selectedDay,
    setCurrentMonth,
    setCurrentYear,
    setHighlightedDays,
    setHighlightedHabit,
    setSelectedDay,
  } = useMonth();
  const advanceMonth = (delta: number) => {
    let newMonth = currentMonth + delta;
    while (newMonth < 0) {
      newMonth += 12;
      setCurrentYear(currentYear - 1);
    }
    while (newMonth > 11) {
      newMonth -= 12;
      setCurrentYear(currentYear + 1);
    }
    setCurrentMonth(newMonth);
  };
  const handleClickThisMonth = () => {
    const today = new Date();
    setCurrentMonth(today.getMonth());
    setCurrentYear(today.getFullYear());
  };
  return (
    <AppBar position="static" color="default" sx={{ marginTop: "10px" }}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "10px" }}
        >
          <Typography
            variant="h6"
            sx={{ color: theme.palette.info.main }}
            component="div"
          >
            {fullMonthsOfYear[currentMonth]} {currentYear}
          </Typography>
          <Box>
            <Button
              variant="outlined"
              color="info"
              onClick={() => advanceMonth(-1)}
            >
              <ArrowBackIosNewIcon color="info" />
            </Button>
            <Button
              variant="outlined"
              color="info"
              onClick={() => handleClickThisMonth()}
            >
              This Month
            </Button>
            <Button
              variant="outlined"
              color="info"
              onClick={() => advanceMonth(1)}
            >
              <ArrowForwardIosIcon color="info" />
            </Button>
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};

export default MonthPageNavigation;
