import { Paper, Typography, TextField, IconButton } from "@mui/material";
import { FormEventHandler, useState } from "react";
import { useHabits } from "../../context/HabitsContext";
import Habit from "../../context/interfaces";
import usePut from "../../hooks/usePut";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { habitNameSx, textFieldStyle } from "./HabitDetailsStyles";

export const HabitDetailsNameBox = () => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState<string>();
  const { habit, setHabit } = useHabits();

  const {
    data: putData,
    isPending: putisPending,
    error: putError,
    updateData,
  } = usePut(`http://localhost:8100/habits/${habit?._id}`);

  const handleChangeName = () => {
    if (!habit) {
      console.error("No habit to update");
      return;
    }
    if (!newName) {
      console.info("No new name");
      return;
    }

    const newHabit: Habit = { ...habit, name: newName as string };
    updateData(newHabit)
      .then(() => {
        setHabit(newHabit);
      })
      .catch((error) => {
        console.error("Failed to update habit", error);
      });
  };

  const handleSubmitName = (event: any) => {
    event.preventDefault();
    setIsEditingName(false);
    if (newName && newName !== habit?.name) {
      handleChangeName();
    }
  };

  return (
    <Paper sx={habitNameSx(habit)} key={`name-${habit?._id}`}>
      {!isEditingName ? (
        <Typography variant="h6" sx={{ color: "white" }}>
          {habit?.name}
        </Typography>
      ) : (
        <form onSubmit={handleSubmitName}>
          <TextField
            variant="standard"
            size="medium"
            sx={textFieldStyle}
            defaultValue={habit?.name}
            onChange={(e) => setNewName(e.target.value)}
          />
        </form>
      )}

      <IconButton
        onClick={() => {
          setIsEditingName(!isEditingName);
          if (newName && newName !== habit?.name) {
            handleChangeName();
          }
        }}
        color="inherit"
      >
        {isEditingName ? (
          <CheckIcon style={{ color: "white" }} />
        ) : (
          <EditIcon style={{ color: "white" }} />
        )}
      </IconButton>
    </Paper>
  );
};
