import { Button, ButtonGroup, Card, Container, Stack } from "@mui/material";
import theme from "../context/theme";
import { mapColorTypeToValue } from "../utils/colorUtils";

// Update the allowed color values to match ButtonGroup color options
type ButtonColor =
  | "inherit"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";

interface DaysSelectButtonsProps {
  checkedState: boolean[];
  handleOnChange: (position: number) => void;
  color?: ButtonColor; // Updated type
  showOnly?: boolean;
}

const showOnlyCardSx = (checkedState: boolean, color: ButtonColor) => {
  let showOnlyCardSx: any = checkedState
    ? { backgroundColor: mapColorTypeToValue(color, "main", theme) }
    : { backgroundColor: mapColorTypeToValue(color, "light", theme) };
  showOnlyCardSx = {
    ...showOnlyCardSx,
    display: "flex",
    width: "40px",
    height: "40px",
    textAlign: "center",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
  };
  return showOnlyCardSx;
};

const DaysSelectButtons = ({
  checkedState,
  handleOnChange,
  color = "primary",
  showOnly = false, //only display the values and not changeables
}: DaysSelectButtonsProps) => {
  return (
    <>
      {showOnly ? (
        <Stack direction={"row"}>
          {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
            <Card
              key={index}
              sx={showOnlyCardSx(checkedState[index], color)}
              onClick={() => handleOnChange(index)}
              style={{ marginRight: "3px", fontFamily:"roboto", fontWeight:500 }}
            >
              {day}
            </Card>
          ))}
        </Stack>
      ) : (
        <ButtonGroup variant="outlined" style={{ marginRight: "-3px" }}>
          {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
            <Button
              key={index}
              variant={checkedState[index] ? "contained" : "outlined"}
              color={color}
              onClick={() => handleOnChange(index)}
              sx={{
                display: "flex",
                width: "41px",
                height: "40px",
                textAlign: "center",
                fontSize: "16px",
                color: "white",
                justifyContent: "center",
                alignItems: "center",
              }}
              style={{ marginRight: "3px" }}
            >
              {day}
            </Button>
          ))}
        </ButtonGroup>
      )}
    </>
  );
};

export default DaysSelectButtons;
