import { FormControl, Paper, TextField } from "@mui/material";
import {
  habitNameSx,
  textFieldStyle,
} from "../pages/HabitDetails/HabitDetailsStyles";

interface HabitFormNameInputProps {
  name: string;
  setName: (name: string) => void;
}

const HabitFormNameInput = ({ name, setName }: HabitFormNameInputProps) => {
  return (
    <Paper sx={habitNameSx(null)}>
      <FormControl margin="none" variant="standard" fullWidth>
        <TextField
          label="New Habit Name"
          variant="standard"
          size="medium"
          InputLabelProps={{
            style: { color: "white" }, // Styles for the label
          }}
          inputProps={{
            style: { color: "white" }, // Styles for the input text
          }}
          sx={textFieldStyle}
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
    </Paper>
  );
};

export default HabitFormNameInput;
