import { Typography } from "@mui/material";
import Habit, { Frequency } from "../../context/interfaces";
import {
  countDaysDoneThisMonth,
  countDaysDoneThisWeek,
} from "../../utils/habitUtils";

interface LongTermHabitStatusProps {
  habit: Habit;
  date?: Date;
}

const LongTermHabitStatus: React.FC<LongTermHabitStatusProps> = ({
  habit,
  date = new Date(),
}) => {
  const timesDone =
    habit.frequency === Frequency.Weekly
      ? countDaysDoneThisWeek({ habit, date })
      : countDaysDoneThisMonth({ habit, date });

  return (
    <Typography
      variant="caption"
      style={{ whiteSpace: "nowrap", color: "white" }}
    >
      {`Done (${timesDone} / ${habit.numberOfPeriods}) times this ${
        habit.frequency === Frequency.Weekly ? "week" : "month"
      }`}
    </Typography>
  );
};

export default LongTermHabitStatus;
