import ThisWeekPageContents from "./ThisWeekPageContents";
import { ThisWeekProvider } from "./ThisWeekContext";

const ThisWeek = () => {
  return (
    <ThisWeekProvider>
      <ThisWeekPageContents />
    </ThisWeekProvider>
  );
};

export default ThisWeek;
