import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

const Navbar: React.FC = () => {
  return (
    <AppBar position="sticky" color="info">
      <Container>
        <Toolbar>
          <Box flexGrow={1}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <EventAvailableIcon sx={{ paddingRight: 1 }} />
              Habit Tracker
            </Typography>
          </Box>
          <Button component={RouterLink} to="/" color="inherit">
            Daily
          </Button>
          <Button component={RouterLink} to="/thisweek" color="inherit">
            Weekly
          </Button>
          <Button component={RouterLink} to="/month" color="inherit">
            Monthly
          </Button>
          <Button component={RouterLink} to="/all-habits" color="inherit">
            All Habits
          </Button>
          <Button component={RouterLink} to="/create" color="inherit">
            Add New Habit
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
