import { Paper, Typography } from "@mui/material";
import Streak, { StreakDisplayType, StreakType } from "../../components/Streak";
import { habitDetailsSx } from "./HabitDetailsStyles";
import Habit from "../../context/interfaces";

export interface StreakBoxProps {
  habit: Habit | null;
  date: Date;
}

const CurrentStreakBox = ({ habit, date }: StreakBoxProps) => {
  return (
    <Paper sx={habitDetailsSx(habit)} key={`currentStreak-${habit?._id}`}>
      <Typography variant="subtitle1" sx={{ color: "white" }}>
        Current Streak:
      </Typography>
      <Streak
        habit={habit}
        date={date}
        variant={"subtitle1"}
        color="white"
        streakDisplayType={StreakDisplayType.NumberAndFrequency}
      />
    </Paper>
  );
};

export default CurrentStreakBox;
