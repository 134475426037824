import {
  Paper,
  Typography,
  IconButton,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { useHabits } from "../../context/HabitsContext";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { habitDetailsSx, selectStyle } from "./HabitDetailsStyles";
import { useEffect, useState } from "react";
import usePut from "../../hooks/usePut";
import Habit, { Frequency } from "../../context/interfaces";

export const HabitDetailsFrequencyBox = () => {
  const [isEditingFrequency, setIsEditingFrequency] = useState(false);
  const [newFrequency, setNewFrequency] = useState<Frequency>(Frequency.Daily);
  const { habit, setHabit } = useHabits();
  const {
    data: putData,
    isPending: putisPending,
    error: putError,
    updateData,
  } = usePut(`http://localhost:8100/habits/${habit?._id}`);
  const handleChangeFrequency: any = (event: any) => {
    if (!habit) {
      console.error("No habit to update");
      return;
    }
    const newHabit: Habit = { ...habit, frequency: newFrequency };
    updateData(newHabit)
      .then(() => {
        setHabit(newHabit);
      })
      .catch((error) => {
        console.error("Failed to update habit", error);
      });
  };
  useEffect(() => {
    if (habit?.frequency) setNewFrequency(habit.frequency);
  }, [habit]);
  // const selectStyle = {
  //   color: "white", // Set text color
  //   fontSize: "1.25rem", // h6 font size
  //   fontWeight: 500, // h6 font weight
  //   lineHeight: 1.6, // h6 line height
  //   "& .MuiSelect-select": {
  //     // Target the internal select element
  //     paddingTop: "0px", // Adjust the padding-top as needed
  //     paddingBottom: "0px", // Adjust the padding-bottom as needed
  //   },
  //   ".MuiSelect-icon": {
  //     // Dropdown icon
  //     color: "white",
  //   },
  //   "&:before": {
  //     // Underline default
  //     borderBottomColor: "white",
  //   },
  //   "&:after": {
  //     // Underline on focus
  //     borderBottomColor: "white",
  //   },
  //   "&:hover:not(.Mui-disabled):before": {
  //     // Underline on hover
  //     borderBottomColor: "white",
  //   },
  // };

  return (
    <Paper sx={habitDetailsSx(habit)} key={`frequency-${habit?._id}`}>
      {!isEditingFrequency ? (
        <Typography variant="h6" sx={{ color: "white" }}>
          {habit?.frequency}
        </Typography>
      ) : (
        <FormControl margin="none" variant="standard" fullWidth>
          <Select
            labelId="frequency-label"
            value={newFrequency}
            onChange={(e) => {
              const newFrequency = e.target.value as Frequency;
              setNewFrequency(newFrequency);
            }}
            sx={selectStyle}
          >
            <MenuItem value={Frequency.Daily}>Daily</MenuItem>
            <MenuItem value={Frequency.Weekly}>Weekly</MenuItem>
            <MenuItem value={Frequency.Monthly}>Monthly</MenuItem>
          </Select>
        </FormControl>
      )}
      <IconButton
        onClick={() => {
          setIsEditingFrequency(!isEditingFrequency);
          if (newFrequency && newFrequency !== habit?.frequency) {
            handleChangeFrequency();
          }
        }}
        color="inherit"
      >
        {isEditingFrequency ? (
          <CheckIcon style={{ color: "white" }} />
        ) : (
          <EditIcon style={{ color: "white" }} />
        )}
      </IconButton>
    </Paper>
  );
};
