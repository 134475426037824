import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    // Define custom properties here if you have any
  }
  interface ThemeOptions {
    // Define custom properties for ThemeOptions here if you have any
  }
}
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0076d6",
    },
    secondary: {
      main: "#00d6cb",
    },
    error: {
      main: "#fe4c42",
    },
    warning: {
      main: "#f2e52b",
    },
    success: {
      main: "#009f7c",
    },background: {
      paper: '#f7f7f7',
    },
  },
  
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
});

export default theme;
