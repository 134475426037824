import { Typography } from "@mui/material";
import Habit, { Frequency } from "../context/interfaces";
import { streak } from "../utils/streakUtils";

export enum StreakDisplayType {
  NumberOnly = "Number Only",
  NumberAndFrequency = "Number and Frequency",
  Sentence = "Sentence",
  Percentage = "Percentage",
}

export enum StreakType {
  CurrentStreak = "Current Streak",
  LongestStreak = "Longest Streak",
  Completions = "Completions",
  PercentageComplete = "Percentage Complete",
}

interface StreakProps {
  habit: Habit | null;
  date: Date;
  color?: string;
  sx?: {};
  variant?: any;
  streakDisplayType?: StreakDisplayType;
  streakType?: StreakType;
}

const Streak: React.FC<StreakProps> = ({
  habit,
  date,
  color,
  sx,
  variant,
  streakDisplayType,
  streakType,
}) => {
  const {
    currentStreak,
    longestStreak,
    frequency,
    completions,
    percentageComplete,
  } = streak(habit, date);
  if (!variant) {
    variant = "caption";
  }
  let periodString = "day";
  if (frequency !== Frequency.Daily) {
    periodString = frequency === Frequency.Weekly ? "week" : "month";
  }
  let style: any = { ...sx, textAlign: "left", whitespace: "nowrap" };
  if (color) {
    style = { ...style, color: color };
  }
  let displayStreak = currentStreak;
  if (streakType) {
    switch (streakType) {
      case StreakType.LongestStreak:
        displayStreak = longestStreak;
        break;
      case StreakType.Completions:
        displayStreak = completions;
        break;
      case StreakType.PercentageComplete:
        displayStreak = Math.round(percentageComplete);
        break;
      default:
        displayStreak = currentStreak;
    }
  }

  return (
    <>
      {streakDisplayType === StreakDisplayType.NumberOnly ? (
        <Typography variant={variant} sx={style}>
          {displayStreak}
        </Typography>
      ) : streakDisplayType === StreakDisplayType.Sentence ? (
        <Typography variant={variant} sx={style}>
          {displayStreak > 0 && `${displayStreak} ${periodString} streak! \n`}
        </Typography>
      ) : streakDisplayType === StreakDisplayType.Percentage ? (
        <Typography variant={variant} sx={style}>
          {displayStreak} %
        </Typography>
      ) : (
        <Typography variant={variant} sx={style}>
          {`${displayStreak} ${periodString.toUpperCase()}S \n`}
        </Typography>
      )}
    </>
  );
};

export default Streak;
