import { createContext, useState, useContext, ReactNode } from "react";
import Habit from "../../context/interfaces";

// Define the shape of the context state
interface MonthContextState {
  highlightedDays: number[];
  currentMonth: number;
  currentYear: number;
  highlightedHabit: Habit | undefined; // Allow for 'undefined' here
  selectedDay: Date | undefined;
  setHighlightedDays: React.Dispatch<React.SetStateAction<number[]>>;
  setCurrentMonth: React.Dispatch<React.SetStateAction<number>>;
  setCurrentYear: React.Dispatch<React.SetStateAction<number>>;
  setHighlightedHabit: React.Dispatch<React.SetStateAction<Habit | undefined>>;
  setSelectedDay: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

// Create the context with a default value
const MonthContext = createContext<MonthContextState | undefined>(undefined);

// Define a hook for easy access to the context
export const useMonth = () => {
  const context = useContext(MonthContext);
  if (!context) {
    throw new Error("useMonth must be used within a MonthProvider");
  }
  return context;
};
type MonthProviderProps = {
  children: ReactNode; // This type allows any valid React child
};
// Define the provider component
export const MonthProvider: React.FC<MonthProviderProps> = ({ children }) => {
  // All the state logic you had in your original component goes here
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [highlightedHabit, setHighlightedHabit] =
    useState<Habit>(/* ... initial habit ... */);
  const [selectedDay, setSelectedDay] = useState<Date>();

  const value = {
    highlightedDays,
    currentMonth,
    currentYear,
    highlightedHabit,
    selectedDay,
    setHighlightedDays,
    setCurrentMonth,
    setCurrentYear,
    setHighlightedHabit,
    setSelectedDay,
  };

  return (
    <MonthContext.Provider value={value}>{children}</MonthContext.Provider>
  );
};
