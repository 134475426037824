import { Grid, Paper } from "@mui/material";
import HabitList from "../../components/HabitList";
import { useHabits } from "../../context/HabitsContext";
import { useEffect, useState } from "react";
import Habit from "../../context/interfaces";
import { useNavigate } from "react-router-dom";
import CreatePageNavigation from "./CreatePageNavigation";
import CreateNewHabitBox from "./CreateNewHabitBox";

const CreatePage = () => {
  const { habit, setHabit, habits, setHabits } = useHabits();
  const [nonArchivedIds, setNonArchivedIds] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const filteredHabits = habits
      .filter((habit: Habit) => !habit.dateArchived)
      .map((habit: Habit) => habit._id);
    if (filteredHabits) setNonArchivedIds(filteredHabits as string[]);
  }, [habit, habits]);
  return (
    <>
      <CreatePageNavigation />
      <Grid container direction={{ xs: "column", lg: "row" }} spacing={2}>
        <Grid item xs={12} lg={5}>
          <Paper
            elevation={4}
            sx={{ marginTop: "20px", padding: "15px 10px 10px 10px" }}
          >
            <CreateNewHabitBox />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Paper
            elevation={4}
            sx={{
              // marginLeft: "20px",
              marginTop: { lg: "20px", xs: "0px" },
              padding: "20px 15px 20px 15px",
            }}
          >
            {habits && (
              <HabitList
                highlightedIds={nonArchivedIds}
                habits={habits}
                title="All Habits"
                onClick={(id) => {
                  navigate(`/habits/${id}`);
                }}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default CreatePage;
