import { useEffect } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { Frequency } from "../../context/interfaces";
import useFetch from "../../hooks/useFetch";
import HabitsDueList from "./HabitsDueList";
import {
  allDailyHabits,
  allMonthlyHabits,
  allWeeklyHabits,
  isHabitDue,
} from "../../utils/habitUtils";
import { useHabits } from "../../context/HabitsContext";

interface TodaysHabitListProps {
  date?: Date;
}

const DaysHabitList: React.FC<TodaysHabitListProps> = ({
  date = new Date(),
}) => {
  const { data, isPending, error } = useFetch(
    `${process.env.REACT_APP_API_URL}/habits`
  );
  const { habits, setHabits } = useHabits();

  useEffect(() => {
    if (data) {
      setHabits(data);
    } else {
      setHabits([]);
    }
  }, [data]);

  const habitsDue = habits.filter((habit) => isHabitDue(habit, date));

  const dailyHabitsDue = habitsDue.filter(
    (habit) => habit.frequency === Frequency.Daily
  );
  const weeklyHabitsDue = habitsDue.filter(
    (habit) => habit.frequency === Frequency.Weekly
  );
  const monthlyHabitsDue = habitsDue.filter(
    (habit) => habit.frequency === Frequency.Monthly
  );

  return (
    <>
      {isPending && <CircularProgress />}
      {error && <Typography variant="body1">{error}</Typography>}
      {dailyHabitsDue.length > 0 && (
        <HabitsDueList
          habitsDue={allDailyHabits(habits, date)}
          date={date}
          title="Daily Habits"
        />
      )}
      {weeklyHabitsDue.length > 0 && (
        <HabitsDueList
          habitsDue={allWeeklyHabits(habits, date)}
          date={date}
          title="Weekly Habits"
        />
      )}
      {monthlyHabitsDue.length > 0 && (
        <HabitsDueList
          habitsDue={allMonthlyHabits(habits, date)}
          date={date}
          title="Monthly Habits"
        />
      )}
    </>
  );
};

export default DaysHabitList;
