import {
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Typography,
} from "@mui/material";
import Habit from "../../context/interfaces";
import { getYYYYmmdd } from "../../utils/dateUtils";
import usePut from "../../hooks/usePut";
import { useHabits } from "../../context/HabitsContext";

interface HabitDueCheckProps {
  habit: Habit;
  date: Date;
}

const HabitDueCheck: React.FC<HabitDueCheckProps> = ({ habit, date }) => {
  const { data, isPending, error, updateData } = usePut(
    `${process.env.REACT_APP_API_URL}/habits/${habit._id}`
  );
  const { habits, setHabits } = useHabits();

  const handleCheckboxChange = (_id: string, checked: boolean) => {
    const dayString = getYYYYmmdd(date);
    let modifiedHabit = habit;
    if (checked) {
      modifiedHabit.daysDone.push(dayString);
    } else {
      modifiedHabit.daysDone = habit.daysDone.filter(
        (date) => date !== dayString
      );
    }
    updateData(modifiedHabit);
    const updatedHabits = habits.map((habit) =>
      habit._id === _id ? modifiedHabit : habit
    );
    setHabits(updatedHabits);
  };

  return (
    <div style={{ display: "inline-block" }}>
      {isPending && <CircularProgress size={24} />}
      {error && <p>{error}</p>}
      <FormControlLabel
        control={
          <Checkbox
            style={{ color: "white" }}
            size="small"
            checked={habit.daysDone.includes(getYYYYmmdd(date))}
            onChange={(e) =>
              handleCheckboxChange(habit._id as string, e.target.checked)
            }
            name={`habit-${habit._id}`}
          />
        }
        label={<Typography variant="subtitle1">{habit.name}</Typography>}
        sx={{ color: "white" }}
      />
    </div>
  );
};

export default HabitDueCheck;
