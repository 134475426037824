import { Paper, Table, TableContainer } from "@mui/material";
import ThisWeekTableHeader from "./ThisWeekTableHeader";
import ThisWeekTableBody from "./ThisWeekTableBody";
import Habit from "../../context/interfaces";

export interface ThisWeekTableProps {
  habits: Habit[];
  setHabits: any;
}

const ThisWeekTable = ({ habits, setHabits }: ThisWeekTableProps) => {
  return (
    <Table>
      <ThisWeekTableHeader />
      <ThisWeekTableBody habits={habits} setHabits={setHabits} />
    </Table>
  );
};

export default ThisWeekTable;
