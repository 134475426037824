import { AppBar, Box, Button, Container, Typography } from "@mui/material";
import {
  changeDateByIncrement,
  getDates,
  monthsOfYear,
} from "../../utils/dateUtils";
import { useThisWeek } from "./ThisWeekContext";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import theme from "../../context/theme";

const ThisWeekNavigation = () => {
  const { dates, setDates } = useThisWeek();
  const increaseDateButton = (increment: number) => {
    let currentDate = new Date();
    if (dates) {
      currentDate = dates.currentDate;
    }
    const newDate = changeDateByIncrement(currentDate, increment);
    const newDates = getDates(newDate);
    setDates(newDates);
  };
  const thisWeekButton = () => {
    let today = new Date();
    const newDates = getDates(today);
    setDates(newDates);
  };
  return (
    <AppBar position="static" color="default" sx={{ marginTop: "10px" }}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "10px" }}
        >
          <Typography
            variant="h6"
            sx={{ color: theme.palette.info.main }}
            component="div"
          >
            {`${
              monthsOfYear[dates?.pastSunday.getMonth() || 0]
            } ${dates?.pastSunday.getFullYear()}`}
            {dates?.comingSaturday.getMonth() !==
              dates?.pastSunday.getMonth() &&
              ` - ${
                monthsOfYear[dates?.comingSaturday.getMonth() || 0]
              } ${dates?.comingSaturday.getFullYear()}`}
          </Typography>
          <Box>
            <Button
              variant="outlined"
              color="info"
              onClick={() => increaseDateButton(-7)}
            >
              <ArrowBackIosNewIcon color="info" />
            </Button>
            <Button
              variant="outlined"
              color="info"
              onClick={() => thisWeekButton()}
            >
              This Week
            </Button>
            <Button
              variant="outlined"
              color="info"
              onClick={() => increaseDateButton(7)}
            >
              <ArrowForwardIosIcon color="info" />
            </Button>
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};

export default ThisWeekNavigation;
