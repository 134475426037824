import {
  Typography,
  Stack,
  CardContent,
  IconButton,
  Box,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Habit, { Frequency } from "../../context/interfaces";
import { Link } from "react-router-dom";
import HabitDueCheck from "./HabitDueCheck";
import LongTermHabitStatus from "./LongTermHabitStatus";
import Streak, { StreakDisplayType } from "../../components/Streak";
import { getYYYYmmdd, parseDateString } from "../../utils/dateUtils";
import theme from "../../context/theme";

interface HabitsDueListProps {
  habitsDue: Habit[];
  date: Date;
  title: string;
}

const HabitsDueList: React.FC<HabitsDueListProps> = ({
  habitsDue,
  date: dateProp,
  title,
}) => {
  const habitSx = (habit: Habit) => {
    let habitSx: any = { margin: "5px" };
    if (habit.daysDone.includes(getYYYYmmdd(date))) {
      if (habit.frequency === Frequency.Daily)
        habitSx = { ...habitSx, backgroundColor: theme.palette.primary.light };
      else
        habitSx =
          habit.frequency === Frequency.Weekly
            ? { ...habitSx, backgroundColor: theme.palette.success.light }
            : { ...habitSx, backgroundColor: theme.palette.error.light };
    } else {
      if (habit.frequency === Frequency.Daily)
        habitSx = { ...habitSx, backgroundColor: theme.palette.primary.main };
      else
        habitSx =
          habit.frequency === Frequency.Weekly
            ? { ...habitSx, backgroundColor: theme.palette.success.main }
            : { ...habitSx, backgroundColor: theme.palette.error.main };
    }
    return habitSx;
  };
  let date = new Date(dateProp);
  date.setHours(0, 0, 0, 0);

  return (
    <Paper
      elevation={4}
      sx={{
        padding: "10px 20px 10px 20px",
        // marginLeft: "20px",
        marginTop: { lg: "20px", xs: "0px" },
        marginBottom: { lg: "0px", xs: "20px" },
      }}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {habitsDue
        .filter((habit) => {
          const parsedDateArchived = parseDateString(habit.dateArchived);
          const dateArchived = parsedDateArchived
            ? new Date(
                parsedDateArchived.year,
                parsedDateArchived.month,
                parsedDateArchived.day
              )
            : null;
          dateArchived?.setHours(0, 0, 0, 0);
          return !dateArchived || (dateArchived && date <= dateArchived);
        })
        .map((habit) => (
          <Paper variant="outlined" sx={habitSx(habit)} key={habit._id}>
            <CardContent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 5,
                paddingLeft: 10,
                paddingBottom: 5,
                paddingRight: 5,
              }}
            >
              <Box>
                <Stack direction="column">
                  <Stack
                    direction="row"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <HabitDueCheck habit={habit} date={date} />

                    <Streak
                      habit={habit}
                      date={date}
                      color={"white"}
                      streakDisplayType={StreakDisplayType.Sentence}
                    />
                  </Stack>

                  {habit.frequency !== Frequency.Daily && (
                    <LongTermHabitStatus habit={habit} date={date} />
                  )}
                </Stack>
              </Box>
              <Link to={`/habits/${habit._id}`}>
                <IconButton color="inherit">
                  <EditIcon style={{ color: "white" }} />
                </IconButton>
              </Link>
            </CardContent>
          </Paper>
        ))}
    </Paper>
  );
};

export default HabitsDueList;
