import { Paper, Typography, IconButton, TextField, Stack } from "@mui/material";
import DaysSelectButtons from "../../components/DaysSelectedButtons";
import { useHabits } from "../../context/HabitsContext";
import Habit, { Frequency } from "../../context/interfaces";
import { habitDetailsSx, textFieldStyle } from "./HabitDetailsStyles";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import usePut from "../../hooks/usePut";

export const HabitDetailsFrequencyDetailsBox = () => {
  const [isEditingFrequencyDetails, setIsEditingFrequencyDetails] =
    useState(false);
  const [newDaysOfWeek, setNewDaysOfWeek] = useState<boolean[]>([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [newNumberOfPeriods, setNewNumberOfPeriods] = useState<
    number | undefined
  >(undefined);
  const { habit, setHabit } = useHabits();

  const {
    data: putData,
    isPending: putisPending,
    error: putError,
    updateData,
  } = usePut(`http://localhost:8100/habits/${habit?._id}`);

  useEffect(() => {
    if (habit?.daysOfWeek) setNewDaysOfWeek(habit.daysOfWeek);
  }, [habit]);

  const changeFrequencyDetails = () => {
    if (!habit) {
      console.error("No habit to update");
      return;
    }
    if (!newDaysOfWeek && !newNumberOfPeriods) {
      console.info("no new habitFrequencyDetails to update");
      return;
    }
    let newHabit: Habit = { ...habit };
    if (newDaysOfWeek) {
      newHabit = { ...newHabit, daysOfWeek: newDaysOfWeek };
    }
    if (newNumberOfPeriods) {
      newHabit = { ...newHabit, numberOfPeriods: newNumberOfPeriods };
    }
    updateData(newHabit)
      .then(() => {
        setHabit(newHabit);
      })
      .catch((error) => {
        console.error("Failed to update habit", error);
      });
  };

  const handleOnChange = (position: number) => {
    const updatedCheckedState = newDaysOfWeek?.map((item, index) =>
      index === position ? !item : item
    );
    setNewDaysOfWeek(updatedCheckedState);
  };

  const handleClickEditFrequencyDetails = (event: any) => {
    event.preventDefault();
  };

  return (
    <Paper sx={habitDetailsSx(habit)} key={`frequencyDetails-${habit?._id}`}>
      {!isEditingFrequencyDetails ? (
        <>
          {habit?.frequency === Frequency.Daily ? (
            <DaysSelectButtons
              checkedState={habit.daysOfWeek}
              handleOnChange={() => {}}
              color="primary"
              showOnly
            ></DaysSelectButtons>
          ) : (
            <Typography variant="h6" sx={{ color: "white" }}>
              {habit?.numberOfPeriods} time
              {habit?.numberOfPeriods === 1 ? "" : "s"} per{" "}
              {habit?.frequency === Frequency.Weekly ? "week" : "month"}
            </Typography>
          )}
        </>
      ) : (
        <>
          {habit?.frequency === Frequency.Daily ? (
            <DaysSelectButtons
              checkedState={newDaysOfWeek}
              handleOnChange={(position) => {
                handleOnChange(position);
              }}
              color="secondary"
              // showOnly
            ></DaysSelectButtons>
          ) : (
            <form onSubmit={changeFrequencyDetails}>
              <Stack direction="row">
                <TextField
                  variant="standard"
                  size="small"
                  type="number"
                  sx={{ ...textFieldStyle, width: "50px" }}
                  defaultValue={habit?.numberOfPeriods}
                  value={newNumberOfPeriods}
                  onChange={(e) => {
                    const newNumber = Number(e.target.value);
                    if (newNumber > 0 && newNumber < 1000) {
                      setNewNumberOfPeriods(newNumber);
                    } else {
                      setNewNumberOfPeriods(1);
                    }
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ ...textFieldStyle, color: "white" }}
                >
                  time{newNumberOfPeriods === 1 ? "" : "s"} per{" "}
                  {habit?.frequency === Frequency.Weekly ? "week" : "month"}
                </Typography>
              </Stack>
            </form>
          )}
        </>
      )}

      <IconButton
        onClick={() => {
          setIsEditingFrequencyDetails(!isEditingFrequencyDetails);
          if (isEditingFrequencyDetails) changeFrequencyDetails();
        }}
        color="inherit"
      >
        {isEditingFrequencyDetails ? (
          <CheckIcon style={{ color: "white" }} />
        ) : (
          <EditIcon style={{ color: "white" }} />
        )}
      </IconButton>
    </Paper>
  );
};
