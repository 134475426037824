import React from "react";
import { TableCell } from "@mui/material";
import Habit, { Frequency, HabitState } from "../../context/interfaces";
import { habitStatus } from "../../utils/habitUtils";
import DoneIcon from "@mui/icons-material/Done";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";

interface ThisWeekHabitTableCellProps {
  habit: Habit;
  date: Date;
  toggleDayDone: (habit: Habit, date: Date) => Promise<void>;
}

const ThisWeekHabitTableCell: React.FC<ThisWeekHabitTableCellProps> = ({
  habit,
  date,
  toggleDayDone,
}) => {
  const handleClick = () => toggleDayDone(habit, date);
  const status = habitStatus(habit, date);
  let color = "blue";

  if (habit.frequency === Frequency.Weekly) {
    color = "green";
  } else if (habit.frequency === Frequency.Monthly) {
    color = "red";
  }

  let icon;
  switch (status) {
    case HabitState.Done:
      icon = <DoneIcon sx={{ color: { color } }} fontSize="small" />;
      break;
    case HabitState.Due:
      icon = (
        <RadioButtonUncheckedIcon sx={{ color: { color } }} fontSize="small" />
      );
      break;
    case HabitState.NotDue:
      icon = (
        <CheckCircleOutlineTwoToneIcon
          sx={{ color: { color } }}
          fontSize="small"
        />
      );
      break;
    default:
      return null; // Returning null in a switch might not be ideal, consider throwing an error or logging
  }

  return (
    <TableCell
      padding="checkbox"
      size="small"
      key={habit._id + date.toISOString()}
      onClick={handleClick}
    >
      {icon}
    </TableCell>
  );
};

export default ThisWeekHabitTableCell;
