import { Button, TableBody, TableCell, TableRow } from "@mui/material";
import { useThisWeek } from "./ThisWeekContext";
import {
  countDaysDoneThisMonth,
  countDaysDoneThisWeek,
  isHabitDueThisWeek,
  sortedHabitsbyFrequency,
} from "../../utils/habitUtils";
import { ThisWeekTableProps } from "./ThisWeekTable";
import Habit, { Frequency, HabitState } from "../../context/interfaces";
import { getYYYYmmdd, parseDateString } from "../../utils/dateUtils";
import axios from "axios";
import ThisWeekHabitTableCell from "./ThisWeekHabitTableCell";

const ThisWeekTableBody = ({ habits, setHabits }: ThisWeekTableProps) => {
  const { dates, setDates } = useThisWeek();
  const toggleDayDone = async (habit: Habit, date: Date) => {
    const url = `${process.env.REACT_APP_API_URL}/habits/${habit._id}`;
    const newHabit = { ...habit };
    const formattedDate = getYYYYmmdd(date); // YYYY-MM-DD
    if (newHabit.daysDone.includes(formattedDate)) {
      // Remove the date from daysDone
      newHabit.daysDone = habit.daysDone.filter((day) => day !== formattedDate);
    } else {
      // Add the date to daysDone
      newHabit.daysDone.push(formattedDate);
    }
    try {
      await axios.put(url, newHabit);
    } catch (err) {
      console.error("An error occurred while updating the habit:", err);
    }
    const updatedHabits = habits.map((h) =>
      h._id === newHabit._id ? newHabit : h
    );
    setHabits(updatedHabits);
  };
  const getColor = (frequency: Frequency) => {
    if (frequency === Frequency.Daily) return "primary";
    if (frequency === Frequency.Weekly) return "success";
    return "error";
  };
  const getHabitDetails = (habit: Habit) => {
    if (!dates) return "";
    switch (habit.frequency) {
      case Frequency.Weekly:
        return ` (${countDaysDoneThisWeek({
          habit,
          date: dates.comingSaturday,
        })}/${habit.numberOfPeriods})`;
      case Frequency.Monthly:
        return ` (${countDaysDoneThisMonth({
          habit,
          date: dates.currentDate,
        })}/${habit.numberOfPeriods})`;
      default:
        return "";
    }
  };
  return (
    <TableBody>
      {dates &&
        sortedHabitsbyFrequency(habits)
          .filter((habit) => {
            const parsedDateArchived = parseDateString(habit.dateArchived);
            const dateArchived = parsedDateArchived
              ? new Date(
                  parsedDateArchived.year,
                  parsedDateArchived.month,
                  parsedDateArchived.day
                )
              : null;
            dateArchived?.setHours(0, 0, 0, 0);
            return (
              isHabitDueThisWeek(habit, dates) &&
              (!dateArchived ||
                (dateArchived && dates.pastSunday <= dateArchived))
            );
          })
          .map((habit, index) => (
            <TableRow key={index} sx={{ border: "none" }}>
              <TableCell sx={{ border: "none", padding: "10px 5px 5px 5px" }}>
                <Button
                  color={getColor(habit.frequency)}
                  size="small"
                  fullWidth
                  href={`/habits/${habit._id}`}
                  variant="contained"
                >
                  {habit.name}
                  {getHabitDetails(habit)}
                </Button>
              </TableCell>
              {dates?.datesThisWeek.map((date, idx) => (
                <ThisWeekHabitTableCell
                  key={`${habit._id}-${date.toISOString()}`} // Using a combination of habit id and date as a key
                  habit={habit}
                  date={date}
                  toggleDayDone={toggleDayDone}
                />
              ))}
            </TableRow>
          ))}
    </TableBody>
  );
};

export default ThisWeekTableBody;
