import React, { FormEvent } from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
  Paper,
  Stack,
} from "@mui/material";
import Habit, { Frequency } from "../context/interfaces";
import { getCurrentDate } from "../utils/dateUtils";
import DaysSelectButtons from "./DaysSelectedButtons";
import {
  habitDetailsSx,
  habitNameSx,
  selectStyle,
  textFieldStyle,
} from "../pages/HabitDetails/HabitDetailsStyles";
import HabitFormNameInput from "./HabitFormNameInput";
import HabitFormFrequencySelect from "./HabitFormFrequencySelect";
import HabitFormFrequencyDetailsInput from "./HabitFormFrequencyDetailsInput";

interface HabitFormProps {
  formType: "Edit" | "Add";
  initialHabit?: Habit;
  onSubmit: (habit: any) => void;
  onCancel: () => void;
  isPending: boolean;
}

const HabitForm: React.FC<HabitFormProps> = ({
  formType,
  initialHabit,
  onSubmit,
  onCancel,
  isPending,
}) => {
  const [name, setName] = React.useState<string>(initialHabit?.name || "");
  const [frequency, setFrequency] = React.useState<Frequency>(Frequency.Daily);
  const [isDaily, setIsDaily] = React.useState<boolean>(true);
  const [isWeekly, setIsWeekly] = React.useState<boolean>(false);
  const [numberOfPeriods, setNumberOfPeriods] = React.useState<number>(1);
  const [checkedState, setCheckedState] = React.useState<boolean[]>([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  React.useEffect(() => {
    if (initialHabit) {
      setName(initialHabit.name);
      setFrequency(initialHabit.frequency);
      setIsDaily(initialHabit.frequency === Frequency.Daily);
      setIsWeekly(initialHabit.frequency === Frequency.Weekly);
      setNumberOfPeriods(initialHabit.numberOfPeriods);
      setCheckedState(initialHabit.daysOfWeek);
    }
  }, [initialHabit]);

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const habit = {
      name,
      frequency,
      daysOfWeek: checkedState,
      numberOfPeriods,
      startDate: getCurrentDate(),
      daysDone: [],
      archived: null,
    };
    onSubmit(habit);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <HabitFormNameInput name={name} setName={setName} />
        <HabitFormFrequencySelect
          frequency={frequency}
          setFrequency={setFrequency}
          setIsDaily={setIsDaily}
          setIsWeekly={setIsWeekly}
        />
        <HabitFormFrequencyDetailsInput
          isDaily={isDaily}
          isWeekly={isWeekly}
          checkedState={checkedState}
          handleOnChange={handleOnChange}
          numberOfPeriods={numberOfPeriods}
          setNumberOfPeriods={setNumberOfPeriods}
        />

        <Box
          sx={{
            margin: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            padding: "5px 0px 5px 10px",
          }}
        >
          <Button onClick={onCancel} variant="outlined">
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color="primary"
            style={{ marginLeft: "5px" }}
            type="submit"
            disabled={isPending}
          >
            {isPending
              ? `${formType === "Edit" ? "Updating..." : "Adding..."}`
              : formType}{" "}
            Habit
          </Button>
        </Box>
      </form>
    </>
  );
};

export default HabitForm;
