export function mapColorTypeToValue(color:string, shade:string, theme:any) {
  const colorShade = shade || 'main';

  switch (color) {
    case 'primary':
      return theme.palette.primary[colorShade];
    case 'secondary':
      return theme.palette.secondary[colorShade];
    case 'error':
      return theme.palette.error[colorShade];
    case 'warning':
      return theme.palette.warning[colorShade];
    case 'info':
      return theme.palette.info[colorShade];
    case 'success':
      return theme.palette.success[colorShade];
    default:
      return color; 
  }
}
