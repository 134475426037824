import { useEffect, useState } from "react";
import Habit from "../../context/interfaces";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { streak } from "../../utils/streakUtils";
import { Container } from "@mui/material";

const Test = () => {
  const { id } = useParams();
  const { data } = useFetch(`${process.env.REACT_APP_API_URL}/habits/${id}`);
  const [habit, setHabit] = useState<Habit>();

  useEffect(() => {
    if (data) {
      setHabit(data);
    }
  }, [data]);
  const { currentStreak, frequency: b } = streak(habit as Habit, new Date());
  return (
    <Container>
      <h1>Test: {habit?.name}</h1>
      <p>{habit && habit.daysDone}</p>
      <p>Streak: {currentStreak}</p>
    </Container>
  );
};

export default Test;
