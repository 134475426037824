import { AppBar, Container, Box, Typography, Button } from "@mui/material";
import theme from "../../context/theme";

const CreatePageNavigation = () => {
  return (
    <AppBar position="static" color="default" sx={{ marginTop: "10px" }}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "10px" }}
        >
          <Typography
            variant="h6"
            sx={{ color: theme.palette.info.main }}
            component="div"
          >
            Add New Habit
          </Typography>
          <Button variant="outlined" color="info" href="/all-habits">
            Go to All Habits
          </Button>
        </Box>
      </Container>
    </AppBar>
  );
};

export default CreatePageNavigation;
