import { Box, Button } from "@mui/material";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useHabits } from "../../context/HabitsContext";
import { useEffect, useState } from "react";
import useDelete from "../../hooks/useDelete";
import { useNavigate } from "react-router-dom";
import { getYYYYmmdd } from "../../utils/dateUtils";
import usePut from "../../hooks/usePut";
import Habit from "../../context/interfaces";

const HabitDetailsButtons = () => {
  const { habit, setHabit, habits, setHabits } = useHabits();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const navigate = useNavigate();
  const id = habit?._id;

  const handleClickDelete = () => {
    setShowDeleteConfirmationModal(true);
  };

  const handleClickUnarchive = () => {
    const newHabit = { ...habit, dateArchived: null };
    updateData(newHabit);
    setHabit(newHabit as Habit);
  };

  const {
    isPending: isDeletePending,
    error: deleteError,
    deleteData,
  } = useDelete(`${process.env.REACT_APP_API_URL}/habits/${id}`);

  const { data, isPending, error, updateData } = usePut(
    `${process.env.REACT_APP_API_URL}/habits/${id}`
  );

  const handleConfirmDelete = () => {
    deleteData().then(() => {
      navigate({ pathname: "/all-habits" });
    });
  };

  const handleClickArchive = () => {
    const newHabit = { ...habit, dateArchived: getYYYYmmdd(new Date()) };
    updateData(newHabit);
    setHabit(newHabit as Habit);
  };
  useEffect(() => {}, [habit, setHabit, usePut, useDelete]);

  return (
    <Box
      sx={{
        margin: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
        padding: "5px 0px 5px 10px",
      }}
    >
      {habit?.dateArchived ? (
        <Button
          variant={"outlined"}
          color="primary"
          style={{ marginRight: "5px" }}
          onClick={handleClickUnarchive}
        >
          UnArchive
        </Button>
      ) : (
        <Button
          variant={"contained"}
          color="primary"
          style={{ marginRight: "5px" }}
          onClick={handleClickArchive}
        >
          Archive
        </Button>
      )}
      <Button variant="contained" color="error" onClick={handleClickDelete}>
        Delete
      </Button>
      <ConfirmationModal
        show={showDeleteConfirmationModal}
        onClose={() => setShowDeleteConfirmationModal(false)}
        onConfirm={handleConfirmDelete}
        question="Are you sure you want to delete this habit?"
      />
    </Box>
  );
};

export default HabitDetailsButtons;
