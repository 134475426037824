import { Paper, Typography } from "@mui/material";
import Streak, { StreakDisplayType, StreakType } from "../../components/Streak";
import { StreakBoxProps } from "./CurrentStreakBox";
import { habitDetailsSx } from "./HabitDetailsStyles";

const HabitCompletionsBox = ({ habit, date }: StreakBoxProps) => {
  return (
    <Paper
      variant="outlined"
      sx={habitDetailsSx(habit)}
      key={`completions-${habit?._id}`}
    >
      <Typography variant="subtitle1" sx={{ color: "white" }}>
        Total completions:{" "}
      </Typography>
      <Streak
        habit={habit}
        date={date}
        variant={"subtitle1"}
        color="white"
        streakDisplayType={StreakDisplayType.NumberOnly}
        streakType={StreakType.Completions}
      />
    </Paper>
  );
};

export default HabitCompletionsBox;
