import {
  FormControl,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  habitDetailsSx,
  textFieldStyle,
} from "../pages/HabitDetails/HabitDetailsStyles";
import DaysSelectButtons from "./DaysSelectedButtons";

interface HabitFormFrequencyDetailsInputProps {
  isDaily: boolean;
  isWeekly: boolean;
  checkedState: boolean[];
  handleOnChange: (position: number) => void;
  numberOfPeriods: number;
  setNumberOfPeriods: (number: number) => void;
}

const HabitFormFrequencyDetailsInput = ({
  isDaily,
  isWeekly,
  checkedState,
  handleOnChange,
  numberOfPeriods,
  setNumberOfPeriods,
}: HabitFormFrequencyDetailsInputProps) => {
  return (
    <Paper sx={habitDetailsSx(null)}>
      <FormControl margin="none" variant="standard" fullWidth>
        {isDaily && (
          <DaysSelectButtons
            checkedState={checkedState}
            handleOnChange={handleOnChange}
            color="secondary"
          />
        )}
        {!isDaily && (
          <>
            <Stack direction="row">
              <TextField
                variant="standard"
                size="small"
                type="number"
                sx={{ ...textFieldStyle, width: "50px" }}
                defaultValue={1}
                value={numberOfPeriods}
                // value={newNumberOfPeriods}
                onChange={(e) => {
                  const newNumber = Number(e.target.value);
                  if (newNumber > 0 && newNumber < 1000) {
                    setNumberOfPeriods(newNumber);
                  } else {
                    setNumberOfPeriods(1);
                  }
                }}
              />
              <Typography
                variant="h6"
                sx={{ ...textFieldStyle, color: "white" }}
              >
                time{numberOfPeriods === 1 ? "" : "s"} per{" "}
                {isWeekly ? "week" : "month"}
              </Typography>
            </Stack>
          </>
        )}{" "}
      </FormControl>
    </Paper>
  );
};

export default HabitFormFrequencyDetailsInput;
