import { useEffect } from "react";
import HabitList from "../../components/HabitList";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Habit from "../../context/interfaces";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import AllHabitsTitleBar from "./AllHabitsTitleBar";
import { useHabits } from "../../context/HabitsContext";

const AllHabits = () => {
  const { habits, setHabits } = useHabits();
  const { data, isPending, error } = useFetch(`${process.env.REACT_APP_API_URL}/habits`);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setHabits(data);
    }
  }, [data, setHabits]);
  // const allIds = (habits: any): number[] => {
  //   return habits.map((habit: Habit) => {
  //     return habit.id;
  //   });
  // };
  const nonArchivedIds = (habits: any): string[] =>
    habits
      .filter((habit: Habit) => !habit.dateArchived)
      .map((habit: Habit) => habit._id);

  return (
    <>
      <Container>
        {error && (
          <Typography variant="h6" color="error">
            Error! {error}
          </Typography>
        )}
        {isPending && <CircularProgress />}
      </Container>
      <AllHabitsTitleBar />
      <Grid container direction={{ xs: "column", lg: "row" }} spacing={2}>
        <Grid item xs={12} lg={5}>
          <Paper elevation={4} sx={{ marginTop: "20px", padding: "20px" }}>
            <Box
              component="img"
              src="GreenCheck.png"
              alt="Description"
              sx={{ width: "100%", height: "auto" }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Paper
            elevation={4}
            sx={{
              // marginLeft: "20px",
              marginTop: { lg: "20px", xs: "0px" },
              padding: "10px 20px 10px 20px",
            }}
          >
            {habits && (
              <HabitList
                highlightedIds={nonArchivedIds(habits)}
                habits={habits}
                title="All Habits"
                onClick={(id) => {
                  navigate(`/habits/${id}`);
                }}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AllHabits;
