import { Paper, Typography } from "@mui/material";
import Streak, { StreakDisplayType, StreakType } from "../../components/Streak";
import { StreakBoxProps } from "./CurrentStreakBox";
import { habitDetailsSx } from "./HabitDetailsStyles";

const LongestStreakBox = ({ habit, date }: StreakBoxProps) => {
  return (
    <Paper sx={habitDetailsSx(habit)} key={`bestStreak-${habit?._id}`}>
      <Typography variant="subtitle1" sx={{ color: "white" }}>
        Longest Streak:
      </Typography>
      <Streak
        habit={habit}
        date={date}
        variant={"subtitle1"}
        color="white"
        streakDisplayType={StreakDisplayType.NumberAndFrequency}
        streakType={StreakType.LongestStreak}
      />
    </Paper>
  );
};

export default LongestStreakBox;
