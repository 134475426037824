import { useHabits } from "../../context/HabitsContext";
import { HabitDetailsNameBox } from "./HabitDetailsNameBox";
import { HabitDetailsFrequencyBox } from "./HabitDetailsFrequencyBox";
import { HabitDetailsFrequencyDetailsBox } from "./HabitDetailsFrequencyDetailsBox";
import CurrentStreakBox from "./CurrentStreakBox";
import LongestStreakBox from "./LongestStreakBox";
import HabitCompletionsBox from "./HabitCompletionsBox";
import HabitCompletionRateBox from "./HabitCompletionRate";
import HabitDetailsButtons from "./HabitDetailsButtons";
import { useEffect } from "react";

// interface HabitDetailsBoxProps {
// habit: Habit;
// handleClickDelete: () => void;
// handleClickEdit: () => void;
// isEditing: boolean;
// handleClickEditFrequency: () => void;
// handleClickEditFrequencyDetails: () => void;
// }

const HabitDetailsBox = () =>
  //   {
  //   handleClickDelete,
  //   handleClickEdit,
  // }:
  // HabitDetailsBoxProps
  {
    const { habit, setHabit } = useHabits();

    let date = new Date();

    return (
      <>
        <HabitDetailsNameBox />
        <HabitDetailsFrequencyBox />
        <HabitDetailsFrequencyDetailsBox />
        <CurrentStreakBox habit={habit} date={date} />
        <LongestStreakBox habit={habit} date={date} />
        <HabitCompletionsBox habit={habit} date={date} />
        <HabitCompletionRateBox habit={habit} date={date} />
        <HabitDetailsButtons />
      </>
    );
  };

export default HabitDetailsBox;
