import { Container, Box, ThemeProvider } from "@mui/material";
import Navbar from "./components/Navbar";
import AllHabits from "./pages/AllHabits/AllHabits";
import DayPage from "./pages/Day/DayPage";
import ThisWeek from "./pages/ThisWeek/ThisWeek";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HabitDetailsPage from "./pages/HabitDetails/HabitDetails";
import NotFound from "./pages/NotFound/NotFound";
import Test from "./pages/Test/Test";
import MonthPage from "./pages/MonthPage/MonthPage";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import theme from "./context/theme";
import { HabitsProvider } from "./context/HabitsContext";
import { MonthProvider } from "./pages/MonthPage/MonthContext";
import CreatePage from "./pages/CreateNewHabit/CreatePage";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <HabitsProvider>
        <MonthProvider>
          <Router>
            <Container maxWidth="lg">
              <Navbar />
              <Box className="content">
                <Routes>
                  <Route path="/" element={<DayPage />} />
                  <Route path="/today" element={<DayPage />} />
                  <Route path="/day/:id" element={<DayPage />} />
                  <Route path="/thisweek" element={<ThisWeek />} />
                  <Route path="/month" element={<MonthPage />} />
                  <Route path="/create" element={<CreatePage />} />
                  <Route path="/all-habits" element={<AllHabits />} />
                  <Route path="/habits/:id" element={<HabitDetailsPage />} />
                  <Route path="/test/:id" element={<Test />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Box>
            </Container>
          </Router>
        </MonthProvider>
      </HabitsProvider>
    </ThemeProvider>
  );
};

export default App;
