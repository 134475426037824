import React, { useEffect } from "react";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { styled } from "@mui/material/styles";
import { useThisWeek } from "./ThisWeekContext";
import { changeDateByIncrement, getDates } from "../../utils/dateUtils";

// dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Date> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main, // Use the main color for selected week
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark, // Optionally, a darker color on hover/focus within the selected week
    },
  }),
  // Apply isHovered styles if the day is part of the hovered week and not selected.
  ...(isHovered &&
    !isSelected && {
      // Ensure this doesn't override selected styles
      backgroundColor: theme.palette.action.hover, // Different color for hover
      "&:hover, &:focus": {
        backgroundColor: theme.palette.action.hover,
      },
    }),
  ...(day.getDay() === 0 && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(day.getDay() === 6 && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Date, dayB: Date | null | undefined) => {
  if (dayB == null) return false;

  // Create new date instances to avoid mutating the originals
  const startOfDayA = new Date(dayA.getTime());
  startOfDayA.setHours(0, 0, 0, 0);

  const startOfDayB = new Date(dayB.getTime());
  startOfDayB.setHours(0, 0, 0, 0);

  // Calculate the start and end of the week for dayA
  const pastSunday = new Date(startOfDayA);
  pastSunday.setDate(startOfDayA.getDate() - startOfDayA.getDay());

  const nextSaturday = new Date(pastSunday);
  nextSaturday.setDate(pastSunday.getDate() + 6);

  // Check if startOfDayB is within the range of pastSunday and nextSaturday
  return startOfDayB >= pastSunday && startOfDayB <= nextSaturday;
};

function Day(
  props: PickersDayProps<Date> & {
    selectedDay?: Date | null;
    hoveredDay?: Date | null;
  }
) {
  const { day, selectedDay, hoveredDay, ...other } = props;
  const isSelectedWeek = isInSameWeek(day, selectedDay);
  const isHoveredWeek = isInSameWeek(day, hoveredDay);

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={isSelectedWeek} // The day is visually selected if it's part of the selected week
      isSelected={isSelectedWeek} // This is for the style condition in the styled component
      isHovered={isHoveredWeek && !isSelectedWeek} // Apply hover style only if it's not the selected week
    />
  );
}

const ThisWeekDateCalendar = () => {
  const [hoveredDay, setHoveredDay] = React.useState<Date | null>(null);
  const [value, setValue] = React.useState<Date | null>(new Date());
  const { dates, setDates } = useThisWeek();

  useEffect(() => {
    if (dates && dates.currentDate) {
      setValue(dates.currentDate);
    }
  }, [dates]);

  const setNewDates = (newDate: Date | null) => {
    if (!newDate) {
      newDate = new Date();
    }
    const newDates = getDates(newDate);
    setDates(newDates);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateCalendar
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          setNewDates(newValue);
        }}
        showDaysOutsideCurrentMonth
        slots={{ day: Day }}
        slotProps={{
          day: (ownerState) => ({
            selectedDay: value,
            hoveredDay,
            onPointerEnter: () => setHoveredDay(ownerState.day),
            onPointerLeave: () => setHoveredDay(null),
          }),
        }}
      />
    </LocalizationProvider>
  );
};
export default ThisWeekDateCalendar;
